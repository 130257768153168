var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.device + "-index",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "bannerTop"
  }, [_c("el-carousel", {
    ref: "slideCarousel",
    attrs: {
      height: _vm.bannerHeight,
      interval: 6000
    }
  }, _vm._l(_vm.img_list, function (item, index) {
    return _c("el-carousel-item", {
      key: index
    }, [_c("a", {
      attrs: {
        href: item.picLink
      }
    }, [_c("img", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.device == "pc",
        expression: "device=='pc'"
      }],
      staticClass: "banner-pc",
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: _vm.title + item.picUrl
      }
    }), _c("img", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.device == "mobile",
        expression: "device=='mobile'"
      }],
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: _vm.title + item.mobilePicUrl
      }
    })]), _c("div", {
      staticClass: "textBox"
    }, [_c("p", [_vm._v(_vm._s(item.title1))]), _c("p", [_vm._v(_vm._s(item.title2))])])]);
  }), 1)], 1), _c("div", {
    ref: "index_product",
    staticClass: "indexBlock indexBlock-product"
  }, [_c("div", {
    ref: "index_product_title",
    staticClass: "content-header animate__animated animate__slow animate__fadeInUp"
  }, [_c("div", {
    staticClass: "title animate__animated animate__slow"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.product_title[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "divide-line"
  })]), _c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }]
  }, [_c("el-carousel", {
    staticStyle: {
      position: "relative"
    },
    attrs: {
      autoplay: false,
      arrow: "always"
    }
  }, _vm._l(_vm.productList, function (item, index) {
    return _c("el-carousel-item", {
      key: index
    }, [_c("li", {
      style: "background:" + item.productColor
    }, [_c("a", {
      attrs: {
        href: item.picLink
      }
    }, [_c("img", {
      attrs: {
        src: _vm.title + item.picUrl
      }
    }), _c("h4", [_vm._v(_vm._s(item.productName))]), _c("pre", {
      domProps: {
        innerHTML: _vm._s(item.productContext)
      }
    }), _c("button", [_vm._v(_vm._s(_vm.mapText.viewMore[parseInt(_vm.language - 1)]))])])])]);
  }), 1)], 1), _c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    ref: "index_product_items",
    staticClass: "animate__animated animate__slow display-none"
  }, _vm._l(_vm.productList, function (item, index) {
    return _c("li", {
      key: index,
      style: "background:" + item.productColor
    }, [_c("a", {
      attrs: {
        href: item.picLink
      }
    }, [_c("img", {
      attrs: {
        src: _vm.title + item.picUrl
      }
    }), _c("h4", [_vm._v(_vm._s(item.productName))]), _c("pre", {
      domProps: {
        innerHTML: _vm._s(item.productContext)
      }
    }), _c("button", [_vm._v(_vm._s(_vm.mapText.viewMore[parseInt(_vm.language) - 1]))])])]);
  }), 0)]), _c("div", {
    ref: "clearC",
    staticClass: "indexBlock-clearC new-ClearC",
    class: {
      "hvr-float": _vm.device == "pc",
      "new-ClearC-en": _vm.language == 2
    }
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "clearC-pic",
    attrs: {
      src: require("../assets/index/clearC_new.jpg")
    }
  }), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }],
    attrs: {
      src: require("../assets/index/clearC_new_mobile.jpg")
    }
  }), _c("div", {
    staticClass: "title",
    class: {
      "title-en": _vm.language == 2
    }
  }, [_c("h3", {
    ref: "clearC_text_h3",
    staticClass: "animate__animated animate__slow"
  }, [_vm._v(_vm._s(_vm.mapText.clearC_title[parseInt(_vm.language) - 1]))]), _c("h3", {
    ref: "clearC_text_h3_2",
    staticClass: "animate__animated animate__slow"
  }, [_vm._v(_vm._s(_vm.mapText.clearC_title_2[parseInt(_vm.language) - 1]))])]), _c("div", {
    ref: "clearC_text_Detail",
    staticClass: "textDetail"
  }, [_c("div", {
    ref: "clearC_textDetail",
    staticClass: "animate__animated animate__slow clearC-content",
    class: {
      "clearC-content-en": _vm.language == 2
    }
  }, _vm._l(_vm.clearc_items, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item-circle",
      class: {
        "item-circle-en": _vm.language == 2
      }
    }, [_c("div", {
      staticClass: "circle-top"
    }, [_c("div", {
      staticClass: "item-title"
    }, [_vm._v(_vm._s(item.title[parseInt(_vm.language) - 1]))]), _vm.language != 2 ? _c("div", [_vm._v(_vm._s(item.subTitle[parseInt(_vm.language) - 1]))]) : _vm._e()]), _c("div", {
      staticClass: "content",
      class: {
        "content-en": _vm.language == 2
      }
    }, [_c("div", [_vm._v(_vm._s(item.content[parseInt(_vm.language) - 1]))])])]);
  }), 0)])]), _c("div", {
    ref: "index_user",
    staticClass: "indexBlock indexBlock-user",
    class: {
      "indexBlock-user-pc": _vm.device == "pc"
    }
  }, [_c("img", {
    staticClass: "user1",
    attrs: {
      src: require("../assets/user1.png")
    }
  }), _c("img", {
    staticClass: "user2",
    attrs: {
      src: require("../assets/user2.png")
    }
  }), _c("div", {
    staticClass: "userContent"
  }, [_c("h3", {
    ref: "index_user_title",
    staticClass: "animate__animated animate__slow"
  }, [_vm._v(_vm._s(_vm.user_title))]), _c("div", {
    staticClass: "divide-line"
  }), _c("p", {
    ref: "index_user_content",
    staticClass: "animate__animated animate__slow"
  }, [_vm._v(_vm._s(_vm.user_content))]), _c("a", {
    attrs: {
      href: _vm.news_list.homeLink
    }
  }, [_vm.device == "pc" ? _c("img", {
    ref: "index_user_img",
    staticClass: "user3 animate__animated animate__slow",
    attrs: {
      src: _vm.title + "uploadImage/" + _vm.user_homeImage
    }
  }) : _vm._e()]), _vm.device == "mobile" ? _c("div", {
    staticClass: "img-block"
  }, [_c("a", {
    attrs: {
      href: _vm.news_list.homeLink
    }
  }, [_vm.device == "mobile" && _vm.language == 1 ? _c("img", {
    ref: "index_user_img",
    staticClass: "user3 animate__animated animate__slow",
    attrs: {
      src: require("../assets/banner/user_story_banner_mobile.jpg")
    }
  }) : _vm._e(), _vm.device == "mobile" && _vm.language == 2 ? _c("img", {
    ref: "index_user_img",
    staticClass: "user3 animate__animated animate__slow",
    attrs: {
      src: require("../assets/banner/user_story_banner_mobile_en.jpg")
    }
  }) : _vm._e(), _vm.device == "mobile" && _vm.language == 3 ? _c("img", {
    ref: "index_user_img",
    staticClass: "user3 animate__animated animate__slow",
    attrs: {
      src: require("../assets/banner/user_story_banner_mobile_tr.jpg")
    }
  }) : _vm._e()])]) : _vm._e()])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLpayer,
      expression: "showLpayer"
    }],
    staticClass: "videomodel"
  }, [_c("div", {
    staticClass: "videomodel-wrapper"
  }, [_c("a", {
    staticClass: "videomodel-close",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: _vm.closePlayer
    }
  }, [_c("i", {
    staticClass: "el-icon-close"
  })]), _c("video", {
    staticClass: "videomodel-video",
    attrs: {
      src: _vm.playLink,
      type: "video/mp4",
      preload: "none",
      autoplay: "",
      controls: "controls",
      poster: ""
    }
  })])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };